import './cookie_manager' // manager the cookies, GA and more

require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');

let option_fancy = {
    loop: true,
    buttons: [
        "zoom",
        //"share",
        "slideShow",
        "fullScreen",
        //"download",
        "thumbs",
        "close"
    ],
    protect: true,
    animationEffect: "zoom",
    transitionEffect: "tube",
    transitionDuration: 750,
    thumbs : {
        autoStart : true
    }
};

$(document).ready(function () {

    // Detect Menu active parent
    if($('a.dropdown-item.active').length > 0){
        $('a.dropdown-item.active').closest('li').addClass('active');
    }

    //JSSCROLLTO
    if ($(".js-scrollTo").length>0) {
        $('.js-scrollTo').on('click', function() { // Au clic sur un élément
            var page = $(this).attr('href'); // Page cible
            var speed = 750; // Durée de l'animation (en ms)
            $('html, body').animate( { scrollTop: $(page).offset().top }, speed ); // Go
            return false;
        });
    }

    if ($(".fancybox").length>0) {
        $(".fancybox").fancybox(option_fancy);
    }

}(jQuery));
