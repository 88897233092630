/**
 * https://www.cnil.fr/fr/solutions-centralisees-de-recueil-de-consentement-aux-cookies-les-gestionnaires-de-tag
 * configure others services with : https://opt-out.ferank.eu/fr/install/
 * Pour voir les options en libellé français : https://i.vgy.me/JTP8zH.png
 */

/**
    * Si "orientation" = middle, mettre "AcceptAllCta" et "highPrivacy" à true
    * Si "orientation" = top ou bottom, mettre "AcceptAllCta" et "highPrivacy" à false (pour le scroll)
**/

//don't forgot to import "js/tarteaucitron/tarteaucitron.js" in the html
if(typeof tarteaucitron !== "undefined"){

  tarteaucitron.init({

    "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
    "cookieName": "tarteaucitron_proxilog", /* Cookie name */

    "orientation": "bottom", /* Banner position (top - middle - bottom) */
    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": false, /* Show the cookie list */

    "adblocker": false, /* Show a Warning if an adblocker is detected */
    "AcceptAllCta" : false, /* Show the accept all button when highPrivacy on */
    "highPrivacy": false, /* Disable auto consent */
    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

    "removeCredit": true, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */
    "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */

    //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */
    //"readmoreLink": "/cookiespolicy" /* Change the default readmore link */
    //"privacyUrl": "", /* Privacy policy url */
  });

  // GOOGLE ANALYTICS (gtag.js)
  // To Google Analytics CNIL cookie script (you need to include the script)
  if(typeof gaProperty !== "undefined" && gaProperty !== "") {
    tarteaucitron.user.gtagUa = gaProperty;
    tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
    (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
  }

}
